/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import axios, { AxiosInstance } from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import jwtDecode from 'jwt-decode';
import store from '@/store';
import { ACTIONS, MUTATIONS } from '@/store/namespaces'
import moment from 'moment'
import { AuthService } from './auth.service';
import { NotificationTypeEnum } from '@/enums/notification-type.enum'
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF'

const qs = require('qs');

export default abstract class ApiClientBase {
  protected instance: AxiosInstance;

  protected baseUrl: string;

  constructor() {
    this.instance = axios.create();
    this.instance.defaults.baseURL = process.env.VUE_APP_API_URL;
    this.baseUrl = '';
    const language = localStorage.getItem('language')

    // Required to work with NSwag auto generated
    this.instance.defaults.transformResponse = [];

    this.instance.interceptors.request.use((request) => {
      request.headers.L = language;
      if (!TokenService.getToken()) {
        delete request.headers.Authorizationn;
      } else {
        request.headers.Authorization = `Bearer ${TokenService.getToken()}`;
      }

      return request;
    })

    this.instance.interceptors.response.use((response) => response,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          const refreshToken = TokenService.getRefreshToken();
          if (!refreshToken) {
            return error;
          }

          return AuthService.refreshToken(refreshToken)
            .then(() => {
              originalRequest.headers.Authorization = `Bearer ${TokenService.getToken()}`;
              return axios(originalRequest);
            })
            .catch(async () => {
              TokenService.destroyToken();
              await router.push('/login');
              return new axios.Cancel('Logout forced.');
            });
        } else if (error.response.status === 403) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.NoAccess)] });
        } else if (error.response.status === 408) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.TimeLimitExceeded)] });
        } else if (error.response.status === 413) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.FileCannotBeBiggerThan50MB)]});
        } else {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.SomethingWentWrong)] });
        }

        return Promise.reject(error);
      });
  }
}
