/* tslint:disable */
/* eslint-disable */

import axios from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import jwtDecode from 'jwt-decode';

const qs = require('qs');

export namespace ApiService {
  export function init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    if (!TokenService.getToken()) {
      delete axios.defaults.headers.common.Authorization;
    } else {
      axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
    }

    axios.interceptors.response.use((response) => response,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          const refreshToken = TokenService.getRefreshToken();
          if (!refreshToken) {
            return error;
          }

          return axios({
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
              client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
              client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
              grant_type: 'refresh_token',
              refresh_token: refreshToken
            }),
            url: 'connect/token',
          }).then((result) => {
            const decoded: any = jwtDecode(result.data.access_token);

            TokenService.saveToken(
              result.data.access_token,
              result.data.refresh_token,
              decoded.exp,
            );

            axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;

            return axios(originalRequest);
          }).catch(async () => {
            TokenService.destroyToken();
            await router.push('/signin');
            return new axios.Cancel('Logout forced.');
          });
        }

        return Promise.reject(error);
      });
  }

  export function get(resource: string, params: any) {
    return axios.get(resource, params);
  }

  export function post(resource: string, params: any) {
    return axios.post(resource, params);
  }

  export function put(resource: string, params: any) {
    return axios.put(resource, params);
  }

  export function del(resource: string, params: any) {
    return axios.delete(resource, params);
  }

  export function send(options: any) {
    return axios(options);
  }
}
