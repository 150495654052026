const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_EXPIRATION_KEY = 'token_expiration';

export namespace TokenService {
  export function getToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  export function saveToken(token: string, refreshToken: string, expirationTimestamp: number) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(TOKEN_EXPIRATION_KEY, expirationTimestamp.toString());
  }

  export function destroyToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRATION_KEY);
  }
}
