import { datePattern, emailPattern, dateRangePattern } from '@/common/Regex.common';
import moment from 'moment';
import _ from 'lodash';
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF';
import { IsIntegerValue } from '@/validators/IsIntegerValue.validator';

export default class Validators {
  isEmpty(v: any) {
    if (_.isString(v) && _.isEmpty(v)) {
      return true;
    }

    return _.isNil(v);
  }

  required(v: any) {
    if (_.isString(v) && _.isEmpty(v)) {
      return i18n.t(LF.ThisFieldIsRequired);
    }

    return !_.isNil(v) || i18n.t(LF.ThisFieldIsRequired);
  }

  email(v: string) {
    return emailPattern.test(v.toLowerCase()) || i18n.t(LF.EmailAddressHasWrongFormat);
  }

  minLength(length: number) {
    return function minLengthValidate(v: string) {
      return (!!v && v.length >= length) || i18n.t(LF.MinTextLength, [length]);
    };
  }

  maxLength(length: number) {
    return function maxLengthValidate(v: string) {
      return !v || v.length <= length || i18n.t(LF.MaxTextLength, [length]);
    };
  }

  dateFormat = function dateFormatValidate(v: string) {
    return !v || datePattern.test(v) || i18n.t(LF.DateFormatMustBeInShortDateFormat);
  };

  dateRangeFormat = function dateRangeFormatValidate(v: string) {
    return !v || dateRangePattern.test(v) || i18n.t(LF.DoubleDatesFormatMustBeInShortDateFormat);
  };

  dateNumbersCorrect = function dateNumbersCorrectValidate(v: string) {
    return !v || moment(v).isValid() || i18n.t(LF.DateIsWrong);
  };

  greaterOrEqualTo(e: number) {
    const { isEmpty } = this;
    return function maxLengthValidate(v: number) {
      return isEmpty(v) || v >= e || i18n.t(LF.ValueCannotBeLowerThan, [e]);
    };
  }

  greaterThan(e: number) {
    const { isEmpty } = this;
    return function maxLengthValidate(v: number) {
      return isEmpty(v) || v > e || i18n.t(LF.ValueMustBeGreaterThan, [e]);
    };
  }

  isIntegerValue = IsIntegerValue;
}
