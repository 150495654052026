import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { ACTIONS, MUTATIONS } from '@/store/namespaces';
import { ArticlesClient, UsersClient } from '@/services/api-clients';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF';

export const actions: ActionTree<RootState, RootState> = {
  async [ACTIONS.GET_USER_INFO]({ commit }): Promise<void> {
    return new UsersClient().getMyInfo().then((userInfoResult) => {
      if (userInfoResult.success && userInfoResult.result) {
        commit(MUTATIONS.SET_USER_INFO, userInfoResult.result);
      } else {
        commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: userInfoResult.errors });
      }
    }).catch(() => {
      commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.UnableToFetchUserDetails)] });
    });
  },

  async [ACTIONS.GET_ARTICLE_GROUPS]({ commit }): Promise<void> {
    return new ArticlesClient().getAllGroups().then((groupsResult) => {
      if (groupsResult.success && groupsResult.result) {
        commit(MUTATIONS.SET_ARTICLE_GROUPS, groupsResult.result);
      } else {
        commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: groupsResult.errors });
      }
    }).catch(() => {
      commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.UnableToFetchArticleGroupsDetails)] });
    });
  },
};
