export const GETTERS = {
  LOADING: 'loading',
  APP_INITIALIZED: 'initialized',
  CAN_ACCESS_APPLICATION_ACCOUNTS: 'canAccessApplicationAccounts',
  USER_ORGANISATION_IS_MASTER: 'userOrganisationIsMaster',
  USER_DISPLAY_NAME: 'userDisplayName',
  USER_ORGANISATIONS_NAMES: 'userOrganisationsNames',
  USER_CAN_MARK_PAYMENT_AS_EXECUTED: 'userCanMarkPaymentAsExecuted',
  USER_CAN_MASS_DELETE_SETTLEMENTS: 'userCanMassDeleteSettlements',
  USER_CAN_SEND_SETTLEMENTS_EMAILS_TO_DRIVERS: 'userCanSendSettlementsEmailsToDrivers',
  USER_HAVE_ACCESS_TO_AUDIT_AREA: 'userHaveAccessToAuditArea',
  USER_CAN_DELETE_DRIVERS: 'userCanDeleteDrivers',
  USER_CAN_EXPORT_SETTLEMENTS: 'userCanExportSettlements',
  USER_CAN_IMPORT_SETTLEMENTS: 'userCanImportSettlements',
  USER_CAN_MODIFY_PETROL_GROSS: 'userCanModifyPetrolGross',
  USER_CAN_MODIFY_RAVAPI_BONUS: 'userCanModifyRavapiBonus',
  USER_CAN_MODIFY_SETTLEMENT_APPLICATION: 'userCanModifySettlementApplication',
  USER_CAN_APPROVE_SETTLEMENTS: 'userCanApproveSettlements',
  USER_CAN_MOVE_SETTLEMENTS_BACK_TO_WAITING: 'userCanMoveSettleentsBackToWaiting',
  USER_CAN_DELETE_PAYOFF: 'userCanDeletePayoff',
  USER_CAN_CANCEL_PAYOFF: 'userCanCancelPayoff',
  USER_CAN_EXPORT_REQUEST_FOR_PAYMENT: 'canExportRequestForPayment',
  USER_HAVE_ACCESS_TO_REPORT_AREA: 'userHaveAccessToReportArea',
  USER_STATISTIC_BUNDLE: 'userGetStatisticBundleId',
  USER_ROLE: 'userRole',
  USER_ID: 'userId',
  ARTICLE_GROUPS: 'articleGroups',
  CONFIRM_IS_OPEN: 'confirmIsOpen',
  VEHICLE_LOG_VISITED: 'vehicleLogVisited',
  USER_CAN_MASS_SET_VEHICLES_COMMISSION: 'canMassSetVehiclesCommission',
  USER_CAN_APPROVE_COLLIDING_SETTLEMENTS: 'canApproveCollidingSettlements',
  USER_CAN_MASS_ADJUST_SETTLEMENT_COMMISSIONS: 'canMassAdjustSettlementCommissions',
  USER_CAN_HOLDOFF_SETTLEMENT_PAYOFF:  'canHoldOffSettlementPayoff',
  USER_CAN_EXPORT_EQUIPMENT_RETURN_REQUEST: 'canExportEquipmentReturnRequest',
  USER_CAN_EXPORT_PROOF_OF_INCOME: 'userCanExportProofOfIncome',
  USER_CAN_REMOVE_DRIVER_CONTACT_FORM: 'userCanRemoveDriverContactForm',
  USER_IS_DRIVER_ACCOUNT: 'userIsDriverAccount',
  USER_DRIVER_ID: 'userDriverId',
};

export const ACTIONS = {
  GET_USER_INFO: 'getUserInfo',
  GET_ARTICLE_GROUPS: 'getArticleGroups',
};

export const MUTATIONS = {
  SET_INITIALIZED: 'setInitialized',
  SET_LOADING: 'setLoading',
  SHOW_SNACKBAR_MESSAGE: 'showSnackbarMessage',
  SHOW_CONFIRM: 'showConfirm',
  FINISH_CONFIRM: 'finishConfirm',
  SET_USER_INFO: 'setUserInfo',
  SET_ARTICLE_GROUPS: 'setArticleGroups',
  VISIT_VEHICLE_LOG: 'visitVehicleLog',
};
