/* tslint:disable */
/* eslint-disable */

import axios, { AxiosError, AxiosResponse } from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import { ApiService } from '@/services/api.service';
import jwtDecode from 'jwt-decode';
import store from '@/store';
import { ACTIONS } from '@/store/namespaces'
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF'

const qs = require('qs');

export namespace AuthService {
  let refreshTokenPromise : Promise<AxiosResponse<any>> | null = null;
  let isRefreshTokenPromiseExecuting = false;

  export async function login(credentials: LoginCredentials) {
    const formData = new FormData();
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('client_secret', process.env.VUE_APP_AUTH_CLIENT_SECRET);
    formData.append('grant_type', 'password');
    formData.append('scope', '');
    formData.append('username', credentials.email);
    formData.append('password', credentials.password);

    const actionResult: LoginResult = {
      success: false,
      errors: [],
    };

    await ApiService.send({
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
        grant_type: 'password',
        scope: 'openid offline_access profile webapi mediadownload mediaupload',
        username: credentials.email,
        password: credentials.password
      }),
      url: 'connect/token',
    }).then(async (result) => {
      const decoded = jwtDecode<{sub: string, email: string, exp: number}>(result.data.access_token);

      TokenService.saveToken(
        result.data.access_token,
        result.data.refresh_token,
        decoded.exp,
      );

      actionResult.success = true;
      await store.dispatch(ACTIONS.GET_USER_INFO);
    }).catch((error: AxiosError<any>) => {
      // Too many requests
      if (error.response?.status === 429) {
        actionResult.errors.push(i18n.t(LF.TooManyLoginAttempts) as string);
        actionResult.retryAfter = error.response.headers['retry-after'] as number | undefined;
      } else if (error.response?.data?.error === 'invalid_grant') {
        actionResult.errors.push(i18n.t(LF.WrongEmailOrPassword) as string);
      } else {
        actionResult.errors.push(i18n.t(LF.ServerIsTemporaryUnavailable) as string);
      }
    });

    return actionResult;
  }

  export function refreshToken(refreshToken: string) {
    if (isRefreshTokenPromiseExecuting && refreshTokenPromise) {
      return refreshTokenPromise;
    }

    isRefreshTokenPromiseExecuting = true;

    refreshTokenPromise = axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      }),
      url: 'connect/token',
    }).then((result) => {
      const decoded: any = jwtDecode(result.data.access_token);

      TokenService.saveToken(
        result.data.access_token,
        result.data.refresh_token,
        decoded.exp,
      );

      axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;

      isRefreshTokenPromiseExecuting = false;
      return result;
    });

    return refreshTokenPromise;
  }
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface LoginResult {
  retryAfter?: number;
  success: boolean;
  errors: string[];
}
