import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { getters } from '@/store/getters';
import { mutations } from '@/store/mutations';
import { actions } from '@/store/actions';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';
import { RootState } from './types';
import { UserRoleEnum } from '@/services/api-clients';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    loading: false,
    snackbar: {
      type: NotificationTypeEnum.Info,
      content: [''],
    },
    visitedHints: {
      vehicleLog: false,
    },
    confirm: {
      title: '',
      content: '',
      result: null,
      cookieSkipKey: undefined,
      isOpen: false,
    },
    initialized: false,
    userInfo: {
      id: '',
      displayName: '',
      organisationsNames: [],
      isMasterOrganisation: false,
      applicationAccountsAccess: false,
      canMarkPaymentAsExecuted: false,
      haveAccessToAuditArea: false,
      massDeleteSettlements: false,
      sendSettlementEmailsToDrivers: false,
      canDeleteDrivers: false,
      canExportSettlements: false,
      canImportSettlements: false,
      canModifyPetrolGross: false,
      canModifySettlementApplication: false,
      canModifyRavapiBonus: false,
      canApproveSettlements: false,
      canMoveSettlementBackToWaiting: false,
      canMassSetVehiclesCommission: false,
      canDeletePayoff: false,
      canCancelPayoff: false,
      canExportRequestForPayment: false,
      role: UserRoleEnum.Specialist,
      statisticBundleId: 0,
      haveAccessToReportArea: false,
      canApproveCollidingSettlements: false,
      canMassAdjustSettlementCommissions: false,
      canHoldOffSettlementPayoff: false,
      canExportEquipmentReturnRequest: false,
      canExportToPdfProofOfIncome: false,
      canRemoveDriverContactForm: false,
      isDriverAccount: true,
      driverId: null
    },
    articleGroups: [],
  },
  modules: {},
  actions,
  mutations,
  getters,
};

export default new Vuex.Store<RootState>(store);
