import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {TokenService} from '@/services/token.service';
import store from '@/store';
import {ACTIONS, GETTERS, MUTATIONS} from '@/store/namespaces';
import {UserRoleEnum} from '@/services/api-clients';
import i18n from '@/i18n/config';
import {LF} from '@/i18n/LF';

Vue.use(VueRouter);

// eslint-disable-next-line no-shadow
enum Routes {
  Login = '/login',
  App = '/',
  ApplicationAccountsList = '/application-accounts',
  AddApplicationAccount = '/application-accounts/form',
  EditApplicationAccount = '/application-accounts/form/:id',
  DriversList = '/drivers',
  AddDriver = '/drivers/form',
  EditDriver = '/drivers/form/:id',
  VehiclesList = '/vehicles',
  AddVehicle = '/vehicles/form',
  EditVehicle = '/vehicles/form/:id',
  SettlementsList = '/settlements',
  AddSettlement = '/settlements/form',
  EditSettlement = '/settlements/form/:id',
  ExportSettlement = '/settlements/export',
  ExportSettlementHistories = '/settlements/export-histories',
  ImportSettlement = '/settlements/import',
  OrganisationsList = '/organisations',
  AddOrganisation = '/organisations/form',
  EditOrganisation = '/organisations/form/:id',
  UsersList = '/users',
  AddUser = '/users/form',
  EditUser = '/users/form/:id',
  AuditList = '/audit',
  PaymentSettings = '/payment-settings',
  SettlementSettings = '/settlement-settings',
  Payoffs = '/payoffs',
  AddPayoff = '/payoffs/form',
  EditPayoff = 'payoffs/form/:id',
  Statistics = '/statistics',
  StatisticsSettings = '/statistics-settings',
  DriverPanel = '/driver-panel'
}

const routes: Array<RouteConfig> = [
  {
    path: Routes.Login,
    name: 'Login',
    component: () => import('@/pages/Login.page.vue'),
    meta: {
      title: () => i18n.t(LF.Login),
      transition: 'fade',
      autoLogin: true,
    },
  },
  {
    path: Routes.DriverPanel,
    component: () => import('@/templates/Driver.template.vue'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters[GETTERS.USER_IS_DRIVER_ACCOUNT]){
        next({
          path: Routes.App
        });
      } else {
        next();
      }
    },
    children: [{ path: Routes.App, component: () => import('@/pages/DriverPanels/DriverPanelForm.page.vue') }]
  },
  {
    path: '',
    component: () => import('@/templates/Main.template.vue'),
    beforeEnter: async (to, from, next) => {
      if (store.getters[GETTERS.USER_IS_DRIVER_ACCOUNT]) {
        next({
          path: Routes.DriverPanel
        });
      } else {
        next();
      }
    },
    children: [{
      path: Routes.App,
      redirect: Routes.DriversList,
    }, {
      path: Routes.ApplicationAccountsList,
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccounts),
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters[GETTERS.CAN_ACCESS_APPLICATION_ACCOUNTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.AuditList,
      component: () => import('@/pages/Audit/AuditList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Audit),
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters[GETTERS.USER_HAVE_ACCESS_TO_AUDIT_AREA]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.AddApplicationAccount,
      name: 'New application account form',
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccountCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditApplicationAccount,
      name: 'Edit application account',
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccountEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.DriversList,
      name: 'Drivers\' list',
      component: () => import('@/pages/Drivers/DriversList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Drivers),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddDriver,
      name: 'New driver form',
      component: () => import('@/pages/Drivers/DriverForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditDriver,
      name: 'Edit driver form',
      component: () => import('@/pages/Drivers/DriverForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.VehiclesList,
      name: 'Vehicles',
      component: () => import('@/pages/Vehicles/VehiclesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Vehicles),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddVehicle,
      name: 'New vehicle form',
      component: () => import('@/pages/Vehicles/VehicleForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehicleCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditVehicle,
      name: 'Edit vehicle form',
      component: () => import('@/pages/Vehicles/VehicleForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehicleEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.SettlementsList,
      name: 'Settlements',
      component: () => import('@/pages/Settlements/SettlementsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Settlements),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddSettlement,
      name: 'New settlement form',
      component: () => import('@/pages/Settlements/SettlementForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditSettlement,
      name: 'Edit settlement form',
      component: () => import('@/pages/Settlements/SettlementForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.ExportSettlementHistories,
      name: 'Export settlements histories',
      component: () => import('@/pages/Settlements/SettlementExportHistory.page.vue'),
      meta: {
        title: () => i18n.t(LF.ExportHistory),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_EXPORT_SETTLEMENTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.ImportSettlement,
      name: 'Import settlements',
      component: () => import('@/pages/Settlements/SettlementImport.page.vue'),
      meta: {
        title: () => i18n.t(LF.ImportSettlements),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_IMPORT_SETTLEMENTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.Payoffs,
      name: 'Payoffs',
      component: () => import('@/pages/Payoffs/PayoffList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Payoffs),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditPayoff,
      name: 'Edit Payoff',
      component: () => import('@/pages/Payoffs/PayoffForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.PayoffEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddPayoff,
      name: 'Add Payoff',
      component: () => import('@/pages/Payoffs/PayoffForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.PayoffCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.OrganisationsList,
      name: 'Organisations list',
      component: () => import('@/pages/Settings/Organisations/OrganisationsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Organisations),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.AddOrganisation,
      name: 'Add organisation',
      component: () => import('@/pages/Settings/Organisations/OrganisationForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.OrganisationCreate),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.EditOrganisation,
      name: 'Edit organisation',
      component: () => import('@/pages/Settings/Organisations/OrganisationForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.OrganisationEdit),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.UsersList,
      name: 'Users list',
      component: () => import('@/pages/Settings/Users/UsersList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Users),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.AddUser,
      name: 'Add user',
      component: () => import('@/pages/Settings/Users/UsersForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.UserCreate),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.EditUser,
      name: 'Edit user',
      component: () => import('@/pages/Settings/Users/UsersForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.UserEdit),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.PaymentSettings,
      name: 'Payment Settings',
      component: () => import('@/pages/Settings/Payments/PaymentSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.PaymentSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.SettlementSettings,
      name: 'Settlement Settings',
      component: () => import('@/pages/Settings/Settlements/SettlementSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementsSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.Statistics,
      name: 'Statistics',
      component: () => import('@/pages/Statistics/Statistics.page.vue'),
      meta: {
        title: () => i18n.t(LF.Statistics),
        requiresAuth: true,
        transition: 'fade',
        requireStatisticBundle: true,
      },
    }, {
      path: Routes.StatisticsSettings,
      name: 'Statistics Settings',
      component: () => import('@/pages/Settings/Statistics/StatisticsSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.StatisticsSettings),
        requiresAuth: true,
        transition: 'fade',
        roles: [UserRoleEnum.SystemAdmin],
      },
    }, {
      path: '*',
      redirect: Routes.Login,
    }],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const language = localStorage.getItem('language');
  if (!language) {
    localStorage.setItem('language', 'Polish');
  } else {
    switch (language) {
      case 'English': i18n.locale = 'en'; break;
      case 'Ukrainian': i18n.locale = 'ua'; break;
      default: i18n.locale = 'pl'; break;
    }
  }

  if (!store.getters[GETTERS.APP_INITIALIZED]) {
    if (TokenService.getToken()) {
      await store.dispatch(ACTIONS.GET_USER_INFO);
      await store.dispatch(ACTIONS.GET_ARTICLE_GROUPS);
    }
    store.commit(MUTATIONS.SET_INITIALIZED, true);
  }

  if (to?.meta?.requiresAuth && !TokenService.getToken()) {
    next({
      path: Routes.Login,
    });
  }

  if (to?.meta?.autoLogin && TokenService.getToken()) {
    next({
      path: Routes.DriversList,
    });
  }

  if (to?.meta?.roles) {
    const allowedRoles = to.meta.roles as UserRoleEnum[];
    const userRole = store.getters[GETTERS.USER_ROLE] as UserRoleEnum;
    if (!allowedRoles.includes(userRole)) {
      next({
        path: Routes.DriversList,
      });
    }
  }

  if (to?.meta?.requireStatisticBundle) {
    const bundleId = store.getters[GETTERS.USER_STATISTIC_BUNDLE] as number | undefined;
    if (!bundleId) {
      next({
        path: Routes.DriversList,
      });
    }
  }

  document.title = to?.meta?.title
    ? `${process.env.VUE_APP_WEBSITE_TITLE_BASE} - ${to.meta.title()}`
    : process.env.VUE_APP_WEBSITE_TITLE_BASE;

  next();
});

export default router;
